import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import {
  useGetRecipeVendorIngredientQuery,
  useAddRecipeIngredientsMutation,
  useUpdateRecipeIngredientsMutation,
  useGetRecipeIngredientUnitsQuery,
} from "../../../../../store/apis/RecipeIngredientApi";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import FormModal from "../../../../shared/formModal/FormModal";
import { Roles } from "../../../../shared/constants";
import "./RecipesIngredients.css";
import {
  RecipeIngredientsFields,
  RecipeIngredientsFields2,
} from "./addRecipesIngredients/forms.constant";
import { FormProvider, useForm } from "react-hook-form";
import useMsalAccount from "utils/useMsalAccount";
import { useSelector } from "react-redux";
import {
  selectVendorId,
  selectVendorName,
} from "store/slices/userSlice/UserSlice";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { Fastfood } from "@mui/icons-material";
import { BaseButton } from "components/shared/BaseButton";

export const RecipesIngredients = () => {
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");
  const { state: locationState } = useLocation();

  const [canEdit] = useState(locationState?.canEdit);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [defaultValues, setDefaultValues] = useState([]);
  const [openFormModal, setOpenFormModal] = useState(false);

  const { roles: userRoles } = useMsalAccount();
  const vendorId = useSelector(selectVendorId);
  const vendorName = useSelector(selectVendorName);

  const isAdminOrMenuTeam = () => {
    const allowedRoles = [Roles.Admin, Roles.MenuTeam];
    return userRoles.some((role) => allowedRoles.includes(role));
  };

  const {
    data: RecipeIngredientData,
    isFetching: loading,
    isError: hasError,
  } = useGetRecipeVendorIngredientQuery(
    {
      recipeId,
      vendorId,
    },
    { skip: !recipeId, refetchOnMountOrArgChange: true }
  );

  const formatQuantity = (quantity, unit) => {
    if (quantity === null || quantity === 0) return " 0.00";
    return `${quantity.toFixed(2)}  ${unit}`;
  };

  const RecipeIngredientsColumn = [
    {
      field: "quantity",
      minWidth: 100,
      flex: 1,
      headerName: "Qty 1",
      renderCell: (params) => {
        const ingredient = RecipeIngredientData?.find(
          (item) => item.id === params.row.id
        );
        return (
          <div>{formatQuantity(ingredient?.quantity, ingredient?.unit)}</div>
        );
      },
    },
    {
      field: "quantity2",
      minWidth: 100,
      flex: 1,
      headerName: "Qty 2",
      renderCell: (params) => {
        const ingredient = RecipeIngredientData?.find(
          (item) => item.id === params.row.id
        );
        return (
          <div>{formatQuantity(ingredient?.quantity2, ingredient?.unit2)}</div>
        );
      },
    },
    {
      field: "ingredient",
      minWidth: 100,
      flex: 2,
      headerName: "Ingredient",
      renderCell: (params) => {
        const ingredientName = params.row.ingredient;
        return isAdminOrMenuTeam() ? (
          <Link
            target="_blank"
            rel="noreferrer"
            to={`/admin/recipes/IngredientDetails/addIngredient?id=${params.row.ingredientId}&newTab=true`}
            style={{ textDecoration: "none", color: "blue" }}
          >
            {ingredientName}
          </Link>
        ) : (
          <div>{ingredientName}</div>
        );
      },
    },
    {
      field: "vendorItemNumber",
      minWidth: 100,
      flex: 1,
      headerName: "Vendor Item #",
    },
    {
      field: "description",
      minWidth: 100,
      flex: 2,
      headerName: "Preparation",
    },
    {
      field: "sortOrder",
      minWidth: 100,
      flex: 1,
      headerName: "Sort",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => (
        <div style={{ textAlign: "center", width: "100%" }}>{params.value}</div>
      ),
    },
    {
      field: "percentToIncludeInAnalysis",
      minWidth: 100,
      flex: 1,
      headerName: "Percentage",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => (
        <div style={{ textAlign: "center", width: "100%" }}>{params.value}</div>
      ),
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={RecipeIngredientData}
            hasEdit={canEdit}
            hasDelete={canEdit}
            editProps={{
              state: {
                title: `Edit RecipeIngredients`,
                data: RecipeIngredientData?.find(
                  (row) => row.id === params.row.id
                ),
                canEdit,
              },
              title: "Recipe Ingredient",
              onClick: () => {
                setIsEdit(true);
                setEditData(params.row);
                setOpenFormModal(true);
              },
            }}
            deleteProps={{
              entityName: params.row?.ingredient,
              apiPath: "deleteRecipesIngredient",
              title: "Recipe Ingredient",
            }}
          />
        );
      },
    },
  ];

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const { watch, handleSubmit, reset } = methods;

  const { data: units } = useGetRecipeIngredientUnitsQuery(
    {
      ingredientId: watch("ingredientId"),
    },
    { skip: !watch("ingredientId"), refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (isEdit) {
      reset(editData);
      setDefaultValues([
        ...defaultValues,
        {
          name: "ingredientId",
          option: [
            {
              id: editData.ingredientId,
              label: editData.ingredient,
            },
          ],
        },
      ]);
    } else {
      reset({});
      setDefaultValues([]);
    }
  }, [isEdit, editData]);

  const [updateRecipeIngredient, { isLoading: updateRecipeIngredientLoading }] =
    useUpdateRecipeIngredientsMutation();
  const [AddRecipeIngredient, { isLoading: addRecipeIngredientLoading }] =
    useAddRecipeIngredientsMutation();

  const isSubmitting =
    addRecipeIngredientLoading || updateRecipeIngredientLoading;

  const onSubmit = (data) => {
    if (isEdit) {
      if (data.quantity2 === "") {
        data.quantity2 = 0;
      }
      updateRecipeIngredient(data);
    } else {
      const formData = {
        ...data,
        recipeId,
      };
      AddRecipeIngredient(formData);
    }
    setOpenFormModal(false);
    setIsEdit(false);
    setEditData();
    reset({});
    setDefaultValues([]);
  };

  return (
    <>
      <Box
        flexGrow={1}
        height={"100%"}
        padding={1}
        sx={{
          display: "flex",
          gap: 1,
          flexDirection: "column",
        }}
      >
        <FlexBox justifyContent={"space-between"}>
          {canEdit && (
            <FlexBox justifyContent={"start"}>
              <BaseButton
                text={"Add new ingredient"}
                endIcon={<Fastfood />}
                state={{ canEdit }}
                onClick={() => {
                  setOpenFormModal(true);
                  setIsEdit(false);
                  setEditData();
                }}
              />
              <BaseButton
                text={"Re Order"}
                To={`reorder?recipeId=${recipeId}`}
              />
            </FlexBox>
          )}
          <FlexBox justifyContent={"end"}>
            <Typography variant="h6" component="h6">
              Vendor: {vendorName}
            </Typography>
          </FlexBox>
        </FlexBox>
        <Box sx={{ flexGrow: 1 }}>
          <BaseDataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "sortOrder", sort: "asc" }],
              },
            }}
            autoHeight={false}
            height={"100%"}
            rows={RecipeIngredientData}
            columns={RecipeIngredientsColumn}
            error={hasError}
            loading={loading}
          />
        </Box>
      </Box>
      {openFormModal && (
        <FormProvider {...methods}>
          <FormModal
            HeaderTitle={isEdit ? "Edit Ingredients" : "Add new ingredient"}
            list={RecipeIngredientsFields}
            list2={RecipeIngredientsFields2}
            units={units || []}
            editData={editData}
            isEdit={isEdit}
            defaultValues={defaultValues}
            open={openFormModal}
            onClickBtn={handleSubmit(onSubmit)}
            isSubmitting={isSubmitting}
            close={() => {
              setOpenFormModal(false);
              reset({});
              setDefaultValues([]);
            }}
          />
        </FormProvider>
      )}
    </>
  );
};
