import {
  Box,
  CircularProgress,
  Divider,
  Fade,
  Modal,
  Typography,
  MenuItem,
  Card,
  Select,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Menus.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import {
  useCopyMenuMutation,
  useGetMenuListQuery,
  useSignMenuMutation,
  useUpdateEnableApproveMyMenuMutation,
} from "../../../store/apis/MenuApis";
import BaseSearch from "../../shared/baseSearch/BaseSearch";

import BaseInput from "../../shared/baseForm/BaseInput";
import { useSeasonSelectQuery } from "../../../store/apis/PrintReportApis";
import {
  useFacilityFeatureByFlagQuery,
  useGetFacilityByIdQuery,
} from "../../../store/apis/FacilityApis";

import { Labels, MenuOptionsList, MenuOptionsList2 } from "./Menu.constant";
import { useForm } from "react-hook-form";
import BaseContent from "../../shared/baseContent/BaseContent";
import { Roles, SCREEN_CONSTANTS } from "../../shared/constants";
import PlatefulTabs from "../PlatefulTabs";
import { useSnackbar } from "notistack";
import Close from "@mui/icons-material/Close";
import DeleteModal from "components/shared/deleteModal/DeleteModal";
import dayjs from "dayjs";
import useMsalAccount from "utils/useMsalAccount";
import { useLocalStorage } from "../../../utils/useLocalStorage";
import BaseActionBox from "components/shared/BaseActionBox";
import { EditCalendar, Print, Receipt, Report } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";
import BaseMenu from "components/shared/BaseMenu";
import CreateBaseColumns from "components/shared/CreateBaseColumn";
import { BaseButton } from "components/shared/BaseButton";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";

export default function Menus() {
  const { state } = useLocation();
  const { roles: userRoles } = useMsalAccount();
  const [searchValue, setSearchValue] = useState(state?.search || "");
  const [debouncedValue, setDebouncedValue] = useState(
    state?.search || Labels.EMPTY_STRING
  );
  const [season, setSeason] = useState(state?.season || Labels.EMPTY_STRING);
  const [localSeason, setLocalSeason] = useLocalStorage("season", season);

  const { enqueueSnackbar } = useSnackbar();

  const [modalToOpenData, setModalToOpenData] = useState("");

  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [openApproveModal, setApproveModal] = useState(false);
  const [openSignMenuModal, setOpenSignMenuModal] = useState(false);
  const [openModalId, setOpenModalId] = useState();
  const [openModalName, setOpenModalName] = useState("");
  const [defaultOption, setDefaultOption] = useState({});

  const isAdmin = () => roleCheck(userRoles, [Roles.Admin, Roles.Menu_Team]);
  const isAdvancedUser = () => roleCheck(userRoles, [Roles.Advanced_User]);

  const {
    data: seasonList,
    isFetching: seasonLoading,
    isSuccess: seasonSuccess,
  } = useSeasonSelectQuery({ showArchived: isAdmin() });

  const { facilityId, accountFromDB } = useMsalAccount();
  const { data: facilityFeature, isFetching: facilityFeatureLoading } =
    useFacilityFeatureByFlagQuery(
      { facilityId, featureFlag: "ApproveMyMenu" },
      { skip: !facilityId }
    );

  const navigate = useNavigate();

  const { data: currentFacility } = useGetFacilityByIdQuery(facilityId, {
    skip: !facilityId,
  });

  useEffect(() => {
    if (modalToOpenData) {
      switch (modalToOpenData) {
        case "Copy":
          setOpenCopyModal(true);
          break;
        case "Sign Menu":
          setOpenSignMenuModal(true);
          break;
        case "Approve My Menu":
          setApproveModal(true);
          break;
      }
    }
  }, [modalToOpenData]);

  useEffect(() => {
    if (openSignMenuModal && !accountFromDB?.dietitianCredentials) {
      navigate("/mycredentials");
    }
  }, [openSignMenuModal]);

  useEffect(() => {
    if (seasonSuccess && !seasonLoading) {
      const idx = seasonList.findIndex((s) => s.defaultSeason === true);
      const defaultOption =
        idx > -1
          ? { id: seasonList[idx]?.id, label: seasonList[idx]?.label }
          : "";

      setDefaultOption(defaultOption);
      setSeason(localSeason || defaultOption?.id);
    }
  }, [seasonSuccess, seasonLoading]);

  useEffect(() => {
    if (season) {
      setLocalSeason(season);
    }
  }, [season]);

  const {
    data: MenusData,
    isFetching: loading,
    isError: hasError,
    error,
    isSuccess,
    refetch: refetchMenus,
  } = useGetMenuListQuery(
    {
      searchTerm: debouncedValue,
      seasonId: season || defaultOption,
      facilityId: facilityId,
    },
    {
      skip: !season || !facilityId || defaultOption == {},
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (hasError) {
      enqueueSnackbar(
        `${error?.status}: ${
          error?.data
            ? JSON.stringify(error.data)
            : "No internet connection. Please check your network"
        }`,
        { variant: "error" }
      );
    } else {
      if (
        MenusData &&
        MenusData?.length < 1 &&
        isSuccess &&
        debouncedValue?.length > 0
      ) {
        enqueueSnackbar(`No menus found for ${debouncedValue}`, {
          variant: "error",
        });
      }
    }
  }, [hasError, MenusData]);

  const menuColumns = [
    {
      field: "menuName",
      flex: 2.8,
      headerName: "Name",
      renderCell: (params) => {
        return (
          <div>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              {params.row.menuName}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "lastEdited",
      flex: 1.5,
      headerName: "Last Edited",
      renderCell: (params) => {
        return (
          <div>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              {params.row.updatedBy}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
              }}
            >
              {params.row.lastUpdated
                ? dayjs(params.row.lastUpdated).format(
                    SCREEN_CONSTANTS.Date_Format
                  )
                : ""}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "htiMenu",
      maxWidth: 90,
      headerName: "Locked",
      headerAlign: "center",
      isBoolean: true,
    },
    {
      field: "dateSigned",
      flex: 1.7,
      headerName: "Date Signed",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              {params.row.signedByName}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
              }}
            >
              {params.row.dateSigned
                ? dayjs(params.row.dateSigned).format(
                    SCREEN_CONSTANTS.Date_Format
                  )
                : ""}
            </Typography>
          </div>
        );
      },
    },
    ...(facilityFeature
      ? [
          {
            field: "isApproveMenuEnabled",
            flex: 1.5,
            headerName: "Approve My Menu",
            headerAlign: "center",
            isBoolean: true,
          },
          {
            field: "approveMyMenuStatus",
            flex: 1.8,
            headerName: "Approval Status",
            headerAlign: "center",
            renderCell: (params) => {
              return (
                <div>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    {params.row.approveMyMenuStatus}
                  </Typography>
                </div>
              );
            },
          },
        ]
      : []),
    {
      field: "actions",
      flex: facilityFeature ? 2.5 : 2.1,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={MenusData}
            flexBoxProps={{
              justifyContent: "end",
            }}
            childrenLocation={"start"}
            hasEdit={true}
            editProps={{
              To: `print?id=${params.row.id}`,
              prefix: "",
              title: params.row.menuName,
              state: {
                search: searchValue,
                season: season,
              },
              icon: <Print />,
            }}
            endChildren={
              <MenuOptionsButton
                idName="menuOptionsButton"
                index={params.row.id}
                setModalToOpen={setModalToOpenData}
                setOpenModalId={setOpenModalId}
                setOpenModalName={setOpenModalName}
                facilityFeature={facilityFeature}
                name={params.row?.menuName}
                List={MenuOptionsList}
                List2={MenuOptionsList2}
                query="?menuId="
                isHTIMenu={params.row?.htiMenu}
                orderTransmissionEnabled={
                  currentFacility?.distributorId > 0 &&
                  currentFacility?.distributorSpecificIdentifier?.length > 0
                }
                state={{
                  search: searchValue,
                  season: season,
                }}
              />
            }
          >
            {" "}
            {(!params.row?.htiMenu && isAdvancedUser()) || isAdmin() ? (
              <BaseButton
                text="Menu Planner"
                To={`MenuPlanner?menuId=${params.row.id}`}
                state={{
                  title: params.row.menuName,
                  data: params.row,
                  search: searchValue,
                  season: season,
                }}
                endIcon={<EditCalendar />}
              />
            ) : null}
          </BaseActionBox>
        );
      },
    },
  ];

  return (
    <BaseContent
      cardOverFlow={false}
      disableBack={true}
      headerText="PlateFul"
      transparent={true}
    >
      <PlatefulTabs />
      <Card
        id="menu-card"
        sx={{
          paddingLeft: "5px",
          borderTopLeftRadius: "0px !important",
          borderTopRightRadius: "0px !important",
          flexGrow: 1,
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            overflow: "hidden",
            flexDirection: "column",
            mt: 1,
            paddingX: 1,
          }}
        >
          <FlexBox justifyContent={"start"}>
            {isAdmin() ? (
              <BaseButton
                component={Link}
                text={"New Menu"}
                To="AddMenus"
                sx={{ marginLeft: "0 !important" }}
                endIcon={<Receipt />}
              />
            ) : null}
          </FlexBox>
          <FlexBox
            sx={{
              my: 1,
              justifyContent: "start",
              flexWrap: "wrap",
            }}
          >
            <BaseSearch
              sx={{ width: "500px" }}
              placeholder="Search..."
              disableUnderline={true}
              type="text"
              value={searchValue}
              setSearchValue={setSearchValue}
              setDebouncedValue={setDebouncedValue}
            />
            <Select
              id="seasonId"
              sx={{ width: "300px" }}
              value={season}
              className="selectInputCategory"
              onChange={(event) => {
                setSeason(event.target.value);
              }}
              size="small"
              displayEmpty
            >
              {seasonList?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FlexBox>
          {openCopyModal && (
            <CopyModal
              open={openCopyModal}
              close={() => {
                setOpenCopyModal(false);
                setModalToOpenData("");
                refetchMenus();
              }}
              id={openModalId}
              menuName={openModalName}
              facilityId={facilityId}
            />
          )}
          {openCopyModal && (
            <CopyModal
              open={openCopyModal}
              close={() => {
                setOpenCopyModal(false);
                setModalToOpenData("");
                refetchMenus();
              }}
              id={openModalId}
              menuName={openModalName}
              facilityId={facilityId}
            />
          )}
          {openSignMenuModal && accountFromDB?.dietitianCredentials && (
            <SignMenuModal
              open={openSignMenuModal}
              close={() => {
                setOpenSignMenuModal(false);
                setModalToOpenData("");
              }}
              id={openModalId}
              menuName={openModalName}
              currentUserData={accountFromDB}
            />
          )}
          {openApproveModal && (
            <ApproveMenuModal
              open={openApproveModal}
              data={MenusData?.filter((item) => item.id == openModalId)}
              close={() => {
                setApproveModal(false);
                setModalToOpenData("");
              }}
              id={openModalId}
            />
          )}
          <Box
            sx={{
              height: "100%",
              flexGrow: 1,
              overflowY: "auto",
              pb: 1,
            }}
          >
            <BaseDataGrid
              rows={MenusData}
              // Added Season Loading in this so when the seasons are loading on initial load it shows loading instead of no data.
              loading={loading || seasonLoading || facilityFeatureLoading}
              columns={CreateBaseColumns(menuColumns)}
              error={hasError}
              autoHeight={false}
              height={"100%"}
              getRowHeight={() => "auto"}
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
              }}
            />
          </Box>
        </Box>
      </Card>
    </BaseContent>
  );
}

function CopyModal({ id, menuName, facilityId, open, close }) {
  const [menuId] = useState(id);
  const [
    copyMenu,
    {
      data: copyMenuData,
      isLoading: copyMenuLoading,
      isSuccess: copyMenuSuccess,
    },
  ] = useCopyMenuMutation({
    refetchOnMountOrArgChange: true,
  });

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    data.id = menuId;
    data.currentFacilityId = facilityId;
    copyMenu(data);
  };

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby={`copy-to-corporate-group-modal-title-${id}`}
      aria-describedby={`copy-to-corporate-group-modal-description-${id}`}
    >
      <Fade in={open}>
        <Box className={"deleteModal"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                marginTop: "-36px",
                padding: "10px",
              }}
            >
              <Close
                color="white"
                size="small"
                cursor="pointer"
                onClick={close}
              />
            </Box>
          </Box>
          <FlexBox
            display={"flex"}
            alignItems={"center"}
            gap={"4px"}
            paddingX={"24px"}
            justifyContent={"space-between"}
          >
            <Report color="error" sx={{ fontSize: "90px" }} />
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <Typography
                id={`copy-to-corporate-group-modal-title-${id}`}
                variant="h5"
                component="h1"
                className="modalTitle"
                sx={{
                  textAlign: "start",
                }}
              >
                Copy Menu
              </Typography>
              <Typography
                id={`copy-to-corporate-group-modal-description-${id}`}
                sx={{
                  textAlign: "center",
                  mt: 2,
                }}
              >
                {copyMenuData ? (
                  <>
                    <Typography
                      id={`copy-to-corporate-group-modal-description-${id}`}
                      sx={{
                        textAlign: "center",
                        mt: 2,
                      }}
                    >
                      This menu has been Copied
                    </Typography>
                    <Typography
                      id={`copy-to-corporate-group-modal-description-${id}`}
                      sx={{
                        textAlign: "center",
                        mt: 2,
                      }}
                    >
                      {` The name of the new menu is  ${
                        !copyMenuData?.menuName ? "" : copyMenuData?.menuName
                      }.`}
                    </Typography>
                  </>
                ) : (
                  <BaseInput
                    label={"New Menu Name"}
                    name={"newMenuName"}
                    defaultValue={`Copy of ${menuName}`}
                    control={control}
                    errors={errors}
                  />
                )}
              </Typography>
            </Box>
          </FlexBox>
          <Divider sx={{ my: 0.5 }} />
          <FlexBox justifyContent={"end"} my={"10px"} px={"24px"}>
            <BaseButton
              text={copyMenuSuccess ? "Ok" : "Copy"}
              colors="error"
              onClick={copyMenuSuccess ? close : handleSubmit(onSubmit)}
              startIcon={
                copyMenuLoading ? (
                  <CircularProgress color="white" size={18} />
                ) : (
                  ""
                )
              }
            />
          </FlexBox>
        </Box>
      </Fade>
    </Modal>
  );
}

function SignMenuModal({ id, menuName, open, close, currentUserData }) {
  const [signMenu, { isLoading: signMenuDataLoading }] = useSignMenuMutation();

  const [menuId] = useState(id);

  const onSubmit = () => {
    signMenu({
      menuId: menuId,
      signedByName: currentUserData.dietitianName,
      signedByCredentials: currentUserData.dietitianCredentials,
    });
  };

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby={`copy-to-corporate-group-modal-title-${id}`}
      aria-describedby={`copy-to-corporate-group-modal-description-${id}`}
    >
      <Fade in={open}>
        <Box className={"deleteModal"}>
          <FlexBox
            display={"flex"}
            alignItems={"center"}
            gap={"4px"}
            paddingX={"24px"}
            justifyContent={"space-between"}
          >
            <Report color="error" sx={{ fontSize: "90px" }} />
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <Typography
                id={`copy-to-corporate-group-modal-title-${id}`}
                variant="h5"
                component="h1"
                className="modalTitle"
                sx={{
                  textAlign: "center",
                }}
              >
                {`Signing Menu ${menuName}`}
              </Typography>
              <Typography
                id={`copy-to-corporate-group-modal-description-${id}`}
                sx={{
                  color: "primary",
                  fontWeight: "bold",
                  textAlign: "left",
                  marginTop: 2,
                }}
              >
                The menu will be signed with the following credentials:
              </Typography>
              <Typography
                id={`copy-to-corporate-group-modal-description-${id}`}
                sx={{
                  textAlign: "left",
                  marginTop: 2,
                }}
              >
                {`Signed By Name: ${currentUserData.dietitianName}`}
              </Typography>
              <Typography
                id={`copy-to-corporate-group-modal-description-${id}`}
                sx={{
                  textAlign: "left",
                }}
              >
                {`Credentials: ${currentUserData.dietitianCredentials}`}
              </Typography>
            </Box>
          </FlexBox>
          <Divider sx={{ my: 0.5 }} />
          <FlexBox justifyContent={"end"} px={"24px"} my={"10px"}>
            <BaseButton
              text={"Sign Menu"}
              colors="warning"
              onClick={() => {
                onSubmit();
                close();
              }}
              startIcon={
                signMenuDataLoading ? (
                  <CircularProgress color="white" size={18} />
                ) : (
                  ""
                )
              }
            />
            <BaseButton colors="error" onClick={close} text={"No"} />
          </FlexBox>
        </Box>
      </Fade>
    </Modal>
  );
}

function ApproveMenuModal({ id, data, open, close }) {
  const [enabled, setEnabled] = useState(
    data ? data[0].isApproveMenuEnabled : false
  );

  const [enableApproveMyMenu, { isLoading: enableApproveMyMenuLoading }] =
    useUpdateEnableApproveMyMenuMutation();

  const [menuId] = useState(id);

  const onSubmit = () => {
    enableApproveMyMenu({
      menuId: menuId,
      enabled,
    });
  };

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby={`copy-to-corporate-group-modal-title-${id}`}
      aria-describedby={`copy-to-corporate-group-modal-description-${id}`}
    >
      <Fade in={open}>
        <Box className={"deleteModal"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                marginTop: "-36px",
                padding: "10px",
              }}
            >
              <Close
                color="white"
                size="small"
                cursor="pointer"
                onClick={close}
              />
            </Box>
          </Box>
          <FlexBox
            display={"flex"}
            alignItems={"center"}
            gap={"4px"}
            paddingX={"24px"}
            justifyContent={"space-between"}
          >
            <Report color="error" sx={{ fontSize: "90px" }} />
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <Typography
                id={`copy-to-corporate-group-modal-title-${id}`}
                variant="h6"
                component="h6"
                fontWeight={"bold"}
                marginBottom={1}
              >
                Approve My Menu
              </Typography>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={enabled}
                    onChange={(e) => setEnabled(e.target.checked)}
                  />
                }
                label={`Turn On "Approve My Menu" Feature`}
              />
            </Box>
          </FlexBox>
          <Divider sx={{ my: 0.5 }} />
          <FlexBox justifyContent={"end"} px={"24px"} my={"10px"}>
            <BaseSubmitButton
              text={"Save"}
              onClick={() => {
                onSubmit();
                close();
              }}
              isSubmitting={enableApproveMyMenuLoading}
            />
          </FlexBox>
        </Box>
      </Fade>
    </Modal>
  );
}

function roleCheck(userRoles, roles) {
  const isInRole = userRoles.some((roleToCheck) => roles.includes(roleToCheck));
  return isInRole;
}

export const MenuOptionsButton = ({
  index,
  name,
  setModalToOpen,
  setOpenModalName,
  setOpenModalId,
  facilityFeature,
  List,
  List2,
  query,
  isHTIMenu,
  orderTransmissionEnabled,
  state,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const { roles: userRoles } = useMsalAccount();

  // menu permissions
  const isAdmin = () => roleCheck(userRoles, [Roles.Admin, Roles.Menu_Team]);
  const isAdvancedUser = () => roleCheck(userRoles, [Roles.Advanced_User]);
  const isMenuSigningUser = () => roleCheck(userRoles, [Roles.Menu_Signing]);
  const isShareMenuUser = () => roleCheck(userRoles, [Roles.Menu_Sharing]);
  const isMenuBulkProductEntryUser = () =>
    roleCheck(userRoles, [Roles.Menu_Product_Bulk_Entry]);

  const notHTIAndAdvancedUser = () => !isHTIMenu && isAdvancedUser();
  // const notHTIAndMenuBulkProductEntryUser = () => !isHTIMenu && isMenuBulkProductEntryUser();

  const canShareMenu = () =>
    (!isHTIMenu && (isAdvancedUser() || isShareMenuUser())) || isAdmin();
  const canSignMenu = () => isMenuSigningUser() && isHTIMenu == isAdmin();

  return (
    <>
      <BaseMenu index={index}>
        {notHTIAndAdvancedUser() || isAdmin()
          ? List?.map((options) => (
              <MenuItem
                key={options.id}
                state={{ title: name, ...state }}
                component={Link}
                to={options?.Link + query + index}
                disableRipple
              >
                {options.optionName}
              </MenuItem>
            ))
          : null}

        {notHTIAndAdvancedUser() ? (
          <MenuItem
            component={Link}
            state={{ title: name, ...state }}
            to={"/plateFul/Menus/copyOtherMenuItems" + query + index}
            disableRipple
          >
            {"Copy Other Menu Items"}
          </MenuItem>
        ) : null}

        {isAdmin()
          ? List2?.map((options) => (
              <MenuItem
                key={options.id}
                component={Link}
                state={{ title: name, ...state }}
                to={options?.Link + query + index}
                disableRipple
              >
                {options.optionName}
              </MenuItem>
            ))
          : null}

        {isAdvancedUser() ? (
          <MenuItem
            key={3}
            component={Link}
            state={{ title: name, ...state }}
            onClick={() => {
              setOpenModalName(name);
              setOpenModalId(index);
              setModalToOpen("Copy");
            }}
            disableRipple
          >
            {"Copy"}
          </MenuItem>
        ) : null}
        {isAdvancedUser() && facilityFeature ? (
          <MenuItem
            key={13}
            component={Link}
            state={{ title: name, ...state }}
            onClick={() => {
              setOpenModalName(name);
              setOpenModalId(index);
              setModalToOpen("Approve My Menu");
            }}
            disableRipple
          >
            Approve My Menu
          </MenuItem>
        ) : null}

        {isMenuBulkProductEntryUser() || isAdmin() ? (
          <MenuItem
            key={12}
            component={Link}
            state={{ title: name, ...state }}
            to={`/plateFul/Menus/MenuBulkProductEntry?menuId=${index}`}
            disableRipple
          >
            {"Bulk Product Entry"}
          </MenuItem>
        ) : null}

        {notHTIAndAdvancedUser() || isAdmin() ? (
          <MenuItem
            key={11}
            component={Link}
            state={{ title: name, ...state }}
            to={`/plateFul/Menus/CustomizePostedMenu?menuId=${index}`}
            disableRipple
          >
            {"Customize Posted Menu"}
          </MenuItem>
        ) : null}

        {canShareMenu() ? (
          <MenuItem
            key={6}
            component={Link}
            state={{ title: name, ...state }}
            to={`/plateFul/Menus/ShareMenu?menuId=${index}`}
            disableRipple
          >
            {"Share Menu"}
          </MenuItem>
        ) : null}

        {canSignMenu() ? (
          <MenuItem
            key={8}
            component={Link}
            state={{ title: name, ...state }}
            onClick={() => {
              setOpenModalName(name);
              setOpenModalId(index);
              setModalToOpen("Sign Menu");
            }}
            disableRipple
          >
            {"Sign Menu"}
          </MenuItem>
        ) : null}

        {orderTransmissionEnabled ? (
          <MenuItem
            key={13}
            component={Link}
            state={{ title: name, ...state }}
            to={`/plateFul/Menus/orderTransmission?menuId=${index}`}
            disableRipple
          >
            {"Order Transmission"}
          </MenuItem>
        ) : null}
        <MenuItem
          key={10}
          component={Link}
          state={{ title: name, ...state }}
          to={`/plateFul/Menus/recipeReprint?menuId=${index}`}
          disableRipple
        >
          {"Recipe Reprint List"}
        </MenuItem>
        {(!isHTIMenu && isAdvancedUser()) || isAdmin() ? (
          <Divider sx={{ my: 0.5 }} />
        ) : null}
        {(!isHTIMenu && isAdvancedUser()) || isAdmin() ? (
          <MenuItem
            className="menuDeleteButton"
            onClick={() => {
              setOpenModal(true);
            }}
            disableRipple
          >
            Delete Menu
          </MenuItem>
        ) : null}
      </BaseMenu>
      {openModal === true ? (
        <DeleteModal
          id={index}
          entityName={name}
          apiPath={"deleteMenu"}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};
