import React, { useState } from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useSafeFormContext from "hooks/useSafeFormContext";
import { eq, toNumber } from "lodash";
import { getNestedProperty } from "utils/helperFunctions";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseInput.md)
 */
export default function BaseInput({
  control,
  errors,
  // Control and Errors should be avoided for future cases, enclose the GenerateForm inside a FormProvider instead

  label,
  validationProps,
  defaultValue,

  unit,
  type,
  variant = "outlined",
  bootstrap = false,
  helperText,
  unitPosition,
  //Hiding all number arrows as requested by Katie
  hideArrows = true,
  ...rest
}) {
  const { control: controlMethod, errors: formErrors } = useSafeFormContext({
    control,
    errors,
  });

  const { multiline, rows, min, max, step, name, id, width, fullWidth } = rest;

  const error = getNestedProperty(formErrors, name);

  const isBootstrapStyle = bootstrap && variant == "standard";
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getInputProps = () => ({
    endAdornment: (
      <>
        {type === "password" && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )}

        {unitPosition == "end" && (
          <InputAdornment position="end">{unit}</InputAdornment>
        )}
      </>
    ),
    startAdornment: (
      <>
        {unitPosition == "start" && (
          <InputAdornment position="start">{unit}</InputAdornment>
        )}
      </>
    ),

    disableUnderline: isBootstrapStyle,
    inputProps: rest?.denser
      ? {
          min: min,
          max: max,
          step: step,
          sx: {
            paddingY: 0,
          },
        }
      : {
          min: min,
          max: max,
          step: step,
        },
  });

  const getInputLabelProps = () => {
    if (isBootstrapStyle || type === "time")
      return {
        shrink: true,
        variant: variant,
      };

    return { variant: variant };
  };

  return (
    <FormControl
      sx={{
        width: width || "100%",
        ...rest.sx,
        ...(rest.hideOutline && {
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }),
      }}
      fullWidth={fullWidth || true}
      variant={variant}
    >
      {controlMethod ? (
        <Controller
          render={({ field: { onChange, value, ref } }) => (
            <TextField
              variant={variant}
              bootstrap={bootstrap.toString()}
              label={label}
              color={rest.color}
              multiline={multiline}
              rows={rows}
              minRows={rest.minRows}
              maxRows={rest.maxRows}
              inputRef={ref}
              error={!!error}
              helperText={error?.message ? error?.message : helperText}
              type={
                type === "password"
                  ? showPassword
                    ? "text"
                    : "password"
                  : type
              }
              value={
                type === "date"
                  ? value?.toString().split("T")[0]
                  : // The previous setup convert numbers to string just because we couldnt understand 0===false
                  type === "number" && !eq(value, "") // The isEmpty check is for a bug that was setting 0 as empty
                  ? toNumber(value) ?? ""
                  : value || ""
              }
              onBlur={(event) => {
                let { value } = event.target;
                value = value.trim(); // Trim the value
                if (type === "number" && !eq(value, ""))
                  value = toNumber(value);
                onChange(value);
              }}
              onChange={(event) => {
                let { value } = event.target;
                if (type === "number" && !eq(value, ""))
                  value = toNumber(value);
                onChange(value);
              }}
              autoComplete={rest.autoComplete || name}
              {...rest}
              slotProps={{
                input: getInputProps(value, onChange),
                inputLabel: getInputLabelProps(),
              }}
              sx={
                hideArrows
                  ? {
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                      ...rest.sx,
                    }
                  : { ...rest.sx }
              }
            />
          )}
          control={controlMethod}
          name={name || id}
          defaultValue={defaultValue}
          rules={{
            ...validationProps,
          }}
        />
      ) : (
        <TextField
          multiline={multiline}
          rows={rows}
          fullWidth={fullWidth || true}
          label={label}
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          {...rest}
          slotProps={{
            inputLabel: getInputLabelProps(),
          }}
        />
      )}
    </FormControl>
  );
}
