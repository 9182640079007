import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";
import { positiveNumber } from "utils/ValidationRegex";
import {
  endingDayValidation,
  startingDayValidation,
} from "utils/commonValidationObjects";
import { REQUIRED_ERROR } from "utils/errorMessages";

export const ByDayMealIds = [
  { value: 0, label: "By Day - Landscape" },
  { value: 1, label: "By Day - Portrait" },
  { value: 2, label: " By Meal" },
];

export const ShoppingListGroupings = [
  { value: 0, label: "Category" },
  { value: 1, label: "Storage Area" },
];

export const MealIds = [
  { id: 1, label: "Breakfast" },
  { id: 2, label: "Lunch" },
  { id: 3, label: "Dinner" },
];

export const ReportFormats = [
  { value: 0, label: "PDF" },
  { value: 1, label: "Excel" },
  { value: 2, label: "Word" },
];

export const ReportDateOptions = [
  {
    value: 2,
    label: "Show first date of each menu cycle at bottom in print out.",
  },
  { value: 3, label: "Show date at top of each day in print out." },
  { value: 1, label: "None." },
];

export const DietReportDateOptions = [
  {
    value: 2,
    label: "Show first date of each menu cycle at bottom in print out.",
  },
  { value: 1, label: "None." },
];

export const ProductionReportDateOptions = [
  { value: 3, label: "Show date at top of each day in print out." },
  { value: 1, label: "None." },
];

// Radio Groups

export const byDayMealId = {
  props: {
    name: "byDayMealId",
    // label: "By Day Meal",
    rows: "column",
    defaultValue: ByDayMealIds[0].value,
  },
  validationProps: {},
  size: 6,
  options: ByDayMealIds,
  type: FORM_TYPES.Radio,
};

export const shoppingListGrouping = {
  props: {
    name: "shoppingListGrouping",
    label: "Group By",
    rows: "column",
    defaultValue: ShoppingListGroupings[0].value,
  },
  validationProps: {},
  size: 6,
  options: ShoppingListGroupings,
  type: FORM_TYPES.Radio,
};

export const formatTypeId = {
  props: {
    name: "formatTypeId",
    label: "Format",
    rows: "column",
    defaultValue: ReportFormats[0].value,
  },
  validationProps: {},
  size: 6,
  options: ReportFormats,
  type: FORM_TYPES.Radio,
};

export const CustomformatTypeId = {
  props: {
    name: "formatTypeId",
    label: "Format",
    rows: "column",
    defaultValue: ReportFormats[0].value,
  },
  validationProps: {},
  size: 6,
  options: [{ value: 0, label: "PDF" }],
  type: FORM_TYPES.Radio,
};

export const reportDateOptionId = {
  props: {
    name: "reportDateOptionId",
    label: "Report Date Option",
    rows: "column",
    defaultValue: 1,
  },
  validationProps: {},
  size: 6,
  options: ReportDateOptions,
  type: FORM_TYPES.Radio,
};

export const dietReportDateOptionId = {
  props: {
    name: "reportDateOptionId",
    label: "Report Date Option",
    rows: "column",
    defaultValue: 1,
  },
  validationProps: {},
  size: 6,
  options: DietReportDateOptions,
  type: FORM_TYPES.Radio,
};

export const productionReportDateOptionId = {
  props: {
    name: "reportDateOptionId",
    label: "Report Date Option",
    rows: "column",
    defaultValue: 1,
  },
  validationProps: {},
  size: 6,
  options: ProductionReportDateOptions,
  type: FORM_TYPES.Radio,
};

// Checkboxes
export const includeBorder = {
  props: {
    name: "includeBorder",
    label: "Include Border",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeDescription = {
  props: {
    name: "includeDescription",
    label: "Include Descriptions",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeNutrients = {
  props: {
    name: "includeNutrients",
    label: "Include Nutrients",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeMenuMorningSnacks = {
  props: {
    name: "includeMenuMorningSnacks",
    label: "Include Morning Snacks",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeMenuAfternoonSnacks = {
  props: {
    name: "includeMenuAfternoonSnacks",
    label: "Include Afternoon Snacks",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeMenuEveningSnacks = {
  props: {
    name: "includeMenuEveningSnacks",
    label: "Include Evening Snacks",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeMenuOtherItems = {
  props: {
    name: "includeMenuOtherItems",
    label: "Include Other Menu Options",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeFacilityLogo = {
  props: {
    name: "includeFacilityLogo",
    label: "Include Logo",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeVendorItems = {
  props: {
    name: "includeVendorItems",
    label: "Include Vendor Item Numbers",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeIngredientsByWeight = {
  props: {
    name: "includeIngredientsByWeight",
    label: "Include Ingredients By Weight",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeNutrientAnalysis = {
  props: {
    name: "includeNutrientAnalysis",
    label: "Include Nutrient Analysis",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeRecipeImage = {
  props: {
    name: "includeRecipeImage",
    label: "Include Recipe Image",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeCost = {
  props: {
    name: "includeCost",
    label: "Include Cost Per Serving",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeCalories = {
  props: {
    name: "includeCalories",
    label: "Include Calories/Protein",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includePortionSize = {
  props: {
    name: "includePortionSize",
    label: "Include Portion Size",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeCarbohydrateCount = {
  props: {
    name: "includeCarbohydrateCount",
    label: "Include Carbohydrate Count",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeSodium = {
  props: {
    name: "includeSodium",
    label: "Include Sodium",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const showHeartHealthy = {
  props: {
    name: "showHeartHealthy",
    label: "Show Heart Healthy Indicator",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const showDiabeticChoice = {
  props: {
    name: "showDiabeticChoice",
    label: "Show Diabetic Choice Indicator",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const showIDDSIAbbreviations = {
  props: {
    name: "showIDDSIAbbreviations",
    label: "Show IDDSI Abbreviations",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeMealOtherMenuOption = {
  props: {
    name: "includeMealOtherMenuOption",
    label: "Include Other Menu Options",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const indicateThinLiquid = {
  props: {
    name: "indicateThinLiquid",
    label: "Indicate Thin Liquid",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeBreakfast = {
  props: {
    name: "includeBreakfast",
    label: "Include Breakfast",
    defaultValue: true,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeResidentName = {
  props: {
    name: "includeResidentName",
    label: "Include Blank For Resident's Name",
    defaultValue: true,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeDayOfMenuLabel = {
  props: {
    name: "includeDayOfMenuLabel",
    label: "Include Day of Menu Label",
    defaultValue: true,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeOtherMenuSelections = {
  props: {
    name: "includeOtherMenuSelections",
    label: "Include 'Other Menu Options'",
    defaultValue: true,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeOtherMenuSelections2 = {
  props: {
    name: "includeOtherMenuSelections",
    label: "Include 'Other Menu Options'",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const onlyPrintOtherMenuSelections = {
  props: {
    name: "onlyPrintOtherMenuSelections",
    label: "Only Print Other Menu Options",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const indicateOtherMenuSelections = {
  props: {
    name: "indicateOtherMenuSelections",
    label: "Indicate Other Menu Options",
    defaultValue: true,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const printSimplified = {
  props: {
    name: "printSimplified",
    label: "Simplified Print",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const printRecipeTagIcons = {
  props: {
    name: "printRecipeTagIcons",
    label: "Include Recipe Tag Icons",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const useLegalSizePostedMenu = {
  props: {
    name: "useLegalSizePostedMenu",
    label: "Legal Sized Paper",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeMealBreakfast = {
  props: {
    name: "includeMealBreakfast",
    label: "Include Breakfast",
    defaultValue: true,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeMealLunch = {
  props: {
    name: "includeMealLunch",
    label: "Include Lunch",
    defaultValue: true,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeMealSupper = {
  props: {
    name: "includeMealSupper",
    label: "Include Dinner",
    defaultValue: true,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeMorningSnacks = {
  props: {
    name: "includeMorningSnacks",
    label: "Include Morning Snacks",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeAfternoonSnacks = {
  props: {
    name: "includeAfternoonSnacks",
    label: "Include Afternoon Snacks",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const includeEveningSnacks = {
  props: {
    name: "includeEveningSnacks",
    label: "Include Evening Snacks",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

export const useLegalSize = {
  props: {
    name: "useLegalSize",
    label: "Legal Sized",
    defaultValue: false,
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.Checkbox,
};

// Text Fields
export const qty1 = {
  props: {
    name: "qty1",
    label: "",
    placeholder: "",
    type: "number",
    min: 0,
  },
  validationProps: {
    pattern: { value: positiveNumber, message: "Please enter positive number" },
  },
  size: 3,
};

export const breakfastQty1 = {
  props: {
    name: "qty1",
    label: "Breakfast",
    type: "number",
    placeholder: "",
    min: 0,
  },
  validationProps: {
    pattern: { value: positiveNumber, message: "Please enter positive number" },
  },
  size: 4,
};

export const qty2 = {
  props: {
    name: "qty2",
    label: "",
    type: "number",
    placeholder: "",
    min: 0,
  },
  validationProps: {
    pattern: { value: positiveNumber, message: "Please enter positive number" },
  },
  size: 3,
};

export const lunchQty2 = {
  props: {
    name: "qty2",
    type: "number",
    label: "Lunch",
    placeholder: "",
    min: 0,
  },
  validationProps: {
    pattern: { value: positiveNumber, message: "Please enter positive number" },
  },
  size: 4,
};

export const qty3 = {
  props: {
    name: "qty3",
    type: "number",
    label: "",
    placeholder: "",
    min: 0,
  },
  validationProps: {
    pattern: { value: positiveNumber, message: "Please enter positive number" },
  },
  size: 3,
};

export const supperQty3 = {
  props: {
    name: "qty3",
    type: "number",
    label: "Dinner",
    placeholder: "",
    min: 0,
  },
  validationProps: {
    pattern: { value: positiveNumber, message: "Please enter positive number" },
  },
  size: 4,
};

export const menuFootNote = {
  props: {
    name: "menuFootNote",
    label: "Footnote",
    multiline: true,
    rows: 4,
    placeholder: "",
  },
  validationProps: {},
  size: 12,
};

export const startingDay = {
  props: {
    name: "startingDay",
    label: "Starting Day",
    type: "number",
    min: 1,
    max: 999,
  },
  validationProps: {
    ...startingDayValidation({ name: "startingDay", endName: "endingDay" }),
  },
  size: 6,
};

export const endingDay = {
  props: {
    name: "endingDay",
    label: "Ending Day",
    type: "number",
    min: 1,
    max: 999,
  },
  validationProps: {
    ...endingDayValidation({ name: "endingDay", startName: "startingDay" }),
  },

  size: 6,
};

export const startDateMonth = {
  props: {
    name: "startDate",
    label: "Select Month",
    placeholder: "Select Month",
    openTo: "month",
    dateFormat: "MMMM YYYY",
    defaultValue: new Date(),
  },
  validationProps: {
    required: "Please select a month",
  },
  size: 6,
  type: FORM_TYPES.DatePicker,
};

export const startMenuDay = {
  props: {
    name: "startMenuDay",
    label: "Start Day",
    type: "number",
    defaultValue: 1,
    min: 0,
  },
  validationProps: {
    pattern: { value: positiveNumber, message: "Please enter positive number" },
  },
  size: 6,
};

export const mealDate = {
  props: {
    name: "mealDate",
    label: "Date of meal",
    placeholder: "Date of meal",
  },
  validationProps: {},
  size: 6,
  type: FORM_TYPES.DatePicker,
};

export const printDay = {
  props: {
    name: "printDay",
    label: "Day",
    type: "number",
    placeholder: "Day",
    min: 0,
  },
  validationProps: {
    pattern: { value: positiveNumber, message: "Please enter positive number" },
  },
  size: 6,
};

export const mealHeaderName = {
  props: {
    name: "mealHeaderName",
    label: "Meal Name",
    placeholder: "Meal Name",
  },
  validationProps: {},
  size: 6,
};

export const firstDate = {
  props: {
    name: "firstDate",
    label: "Day 1 of Menu Cycle",
    placeholder: "",
    defaultValue: "",
  },
  validationProps: {
    required: REQUIRED_ERROR("First Day of Menu Cycle"),
  },
  size: 6,
  type: FORM_TYPES.DatePicker,
};

export const lastDate = {
  props: {
    name: "lastDate",
    label: "Last Day of Menu Cycle",
    placeholder: "",
    defaultValue: "",
  },
  validationProps: {
    required: REQUIRED_ERROR("Last Day of Menu Cycle"),
  },
  size: 6,
  type: FORM_TYPES.DatePicker,
};

export const reportFooter = {
  props: {
    name: "reportFooter",
    label: "Report Footer",
    placeholder: "",
    multiline: true,
  },
  validationProps: {},
  size: 12,
};

// Typographies / Texts
export const censusText = {
  props: {
    text: "Census",
  },
  type: FORM_TYPES.Text,
  size: 12,
};

export const regularQuantitiesText = {
  props: {
    text: "Regular Quantities",
  },
  type: FORM_TYPES.Text,
  size: 12,
};

export const breakfastText = {
  props: {
    text: "Breakfast",
  },
  type: FORM_TYPES.Text,
  size: 2,
};

export const lunchText = {
  props: {
    text: "Lunch",
  },
  type: FORM_TYPES.Text,
  size: 2,
};

export const supperText = {
  props: {
    text: "Dinner",
  },
  type: FORM_TYPES.Text,
  size: 2,
};

export const mealSnackText = {
  props: {
    text: "Meal/Snacks",
  },
  size: 12,
  type: FORM_TYPES.Text,
};

export const calendarDatesText = {
  props: {
    text: "Calendar Dates",
  },
  type: FORM_TYPES.Text,
};

export const reportFooterText = {
  props: {
    text: "Report Footer",
  },
  type: FORM_TYPES.Text,
};

export const alternateMenuItemtext = {
  props: {
    text: `When offering the alternate menu items, please make sure a
    complete meal is served. Water, assorted beverages and
    condiments offered at each meal. If a menu item is crossed off
    due to an allergy, please serve an appropriate substitution. RD
    Signature:`,
    className: "print-heading print-title-box",
  },
  type: FORM_TYPES.Text,
};

// Selects
export const selectedMealId = {
  props: {
    name: "selectedMealId",
    label: "Meal",
    defaultValue: MealIds[1],
  },
  validationProps: {},
  size: 6,
  options: MealIds,
  type: FORM_TYPES.Select,
};

export const printMealId = {
  props: {
    name: "printMealId",
    label: "Print Meal",
    defaultValue: MealIds[1],
  },
  validationProps: {},
  size: 6,
  options: MealIds,
  type: FORM_TYPES.Select,
};

// Offsets
export const offset6 = {
  props: {},
  size: 6,
  type: FORM_TYPES.Offset,
};

export const offset8 = {
  props: {},
  size: 6,
  type: FORM_TYPES.Offset,
};

export const offset4 = {
  props: {},
  size: 6,
  type: FORM_TYPES.Offset,
};

export const offset3 = {
  props: {},
  size: 3,
  type: FORM_TYPES.Offset,
};

export const offset12 = {
  props: {},
  size: 12,
  type: FORM_TYPES.Offset,
};
